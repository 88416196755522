/* Color Definition */
/* Screen Size */
/* Size Definition */
/* Aligning */
#landing .content .account, .text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.center {
  margin: 0 auto;
}

.full-filled {
  width: 100%;
  height: 100%;
}

.adap-bg-img {
  background-repeat: no-repeat;
  background-size: contain;
}

/* mixin */
/* stylelint-disable media-query-no-invalid */
/* stylelint-disable scss/at-function-pattern */
.btn:link,
.btn:hover,
.btn:visited,
.btn:active {
  text-decoration: none;
}

/* stylelint-disable selector-id-pattern */
#termsOfUserLink,
#privacyPolicyLink {
  cursor: pointer;
}
#termsOfUserLink:hover,
#privacyPolicyLink:hover {
  text-decoration: underline;
}

.modal iframe {
  width: 100%;
  height: 50vh;
}

#landing {
  min-height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: url(../../landing/img/bg_cloud-2b4f97de.svg) repeat;
  background-size: contain;
}
@media (max-width: 1365px) {
  #landing {
    background-image: none;
    background-color: #f4f4f4;
  }
}
#landing .container-body {
  -webkit-box-flex: 1 0 auto;
  -moz-box-flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
#landing .footer {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}
#landing .logo-area {
  position: absolute;
}
@media (max-width: 1365px) {
  #landing .logo-area {
    position: static;
  }
}
#landing .logo-area .logo-icon {
  width: 220px;
  height: 120px;
}
@media (max-width: 1365px) {
  #landing .logo-area .logo-icon {
    width: 145px;
    height: 80px;
  }
}
@media (max-width: 767px) {
  #landing .logo-area .logo-icon {
    margin: auto;
    margin-top: 60px;
    width: 118px;
    height: 65px;
  }
}
#landing .main {
  position: relative;
  min-width: 380px;
}
#landing .main.oauth {
  display: none;
}
#landing .v-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#landing .text-content-v-position {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media (max-width: 1365px) {
  #landing .text-content-v-position {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }
}
#landing .align-left {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
@media (max-width: 1365px) {
  #landing .align-left {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }
}
#landing .align-right {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}
@media (max-width: 1365px) {
  #landing .align-right {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }
}
#landing .align-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
@media (max-width: 1365px) {
  #landing .align-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }
}
#landing .col-container {
  float: left;
  width: 50%;
}
@media (max-width: 1365px) {
  #landing .col-container {
    width: 100%;
  }
}
#landing .content {
  height: calc(100vh - 32px);
  min-height: 461px;
  color: #969696;
}
@media (max-width: 1365px) {
  #landing .content {
    margin-top: 32px;
    height: auto;
    min-height: auto;
  }
}
@media (max-width: 1023px) {
  #landing .content {
    margin-top: 145px;
    height: auto;
    min-height: auto;
  }
}
@media (max-width: 767px) {
  #landing .content {
    margin-top: 57px;
  }
}
#landing .content .browser-frame {
  position: relative;
  padding: 40px 10px 6px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  #landing .content .browser-frame {
    padding: 0;
    background-color: #f4f4f4;
  }
}
#landing .content .browser-frame .iframe-video {
  width: 700px;
  height: 438px;
}
@media (max-width: 2399px) {
  #landing .content .browser-frame .iframe-video {
    width: 650px;
    height: 406px;
  }
}
@media (max-width: 1919px) {
  #landing .content .browser-frame .iframe-video {
    width: 550px;
    height: 344px;
  }
}
@media (max-width: 1365px) {
  #landing .content .browser-frame .iframe-video {
    width: 495px;
    height: 308px;
  }
}
@media (max-width: 767px) {
  #landing .content .browser-frame .iframe-video {
    width: 340px;
    height: 190px;
  }
}
#landing .content .browser-frame .opration-icon {
  position: absolute;
  top: 5px;
  left: 0;
  width: 60px;
  height: 40px;
}
@media (max-width: 767px) {
  #landing .content .browser-frame .opration-icon {
    display: none;
  }
}
#landing .content .section.text-intro-area {
  padding-right: 230px;
}
@media (max-width: 2399px) {
  #landing .content .section.text-intro-area {
    padding-right: 180px;
  }
}
@media (max-width: 1919px) {
  #landing .content .section.text-intro-area {
    padding-right: 80px;
  }
}
@media (max-width: 1365px) {
  #landing .content .section.text-intro-area {
    padding-right: 0;
  }
}
#landing .content .section .title {
  font-size: 38px;
  font-weight: 100;
  color: #575757;
}
@media (max-width: 1365px) {
  #landing .content .section .title {
    font-size: 34px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  #landing .content .section .title {
    font-size: 24px;
    text-align: center;
  }
}
#landing .content .section .desc {
  margin: 15px 0 35px;
  font-size: 18px;
  text-align: left;
  color: #969696;
  letter-spacing: 0.2px;
}
@media (max-width: 1365px) {
  #landing .content .section .desc {
    margin: 12px 0 24px;
    font-size: 18px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  #landing .content .section .desc {
    margin: 8px 0 24px;
    font-size: 14px;
    text-align: center;
  }
}
#landing .content .section .hyperlink-wrap {
  margin: 0;
}
@media (max-width: 1365px) {
  #landing .content .section .hyperlink-wrap {
    margin: 0 0 48px;
  }
}
@media (max-width: 767px) {
  #landing .content .section .hyperlink-wrap {
    margin: 0 0 70px;
  }
}
#landing .content .section .hyperlink-wrap .btn-login {
  width: 204px;
  line-height: 46px;
  font-size: 18px;
  font-weight: 200;
  color: #ffffff;
  background-color: #64be00;
  transition: all 0.5s;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
#landing .content .section .hyperlink-wrap .btn-login:hover {
  background-color: #3c9f00;
}
@media (max-width: 1365px) {
  #landing .content .section .hyperlink-wrap .btn-login {
    width: 170px;
    font-size: 17px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  #landing .content .section .hyperlink-wrap .btn-login {
    width: 127px;
    font-size: 13px;
    line-height: 31px;
  }
}
#landing .content .live-demo {
  display: block;
}
#landing .content .live-demo::before {
  display: block;
  height: 20%;
  min-height: 0;
  content: "";
}
#landing .content .demo-text {
  font-size: 30px;
  line-height: 36px;
  color: #888888;
}
@media (min-width: 2400px) {
  #landing .content .demo-text {
    font-size: 75px;
    line-height: 90px;
  }
}
#landing .content .account {
  display: inline-block;
  margin-top: 9.6px;
  line-height: 32px;
  font-size: 20px;
}
@media (min-width: 2400px) {
  #landing .content .account {
    margin-top: 24px;
    font-size: 50px;
    line-height: 80px;
  }
}
#landing .content .deco {
  margin-right: 0.25em;
  margin-bottom: 9.6px;
  width: 32px;
  height: 32px;
}
@media (min-width: 2400px) {
  #landing .content .deco {
    margin-bottom: 24px;
    width: 80px;
    height: 80px;
  }
}
#landing .content .btn-demo {
  width: 170px;
  color: #00b2ff;
  border: 2px solid #00b2ff;
}
@media (min-width: 2400px) {
  #landing .content .btn-demo {
    width: 425px;
  }
}
#landing .content .btn-demo:hover {
  font-weight: 400;
  color: #ffffff;
  background-color: #00b2ff;
  border-color: #0098e3;
}
#landing .content-video {
  margin-bottom: 0;
}
@media (max-width: 1365px) {
  #landing .content-video {
    margin-bottom: 28px;
  }
}
@media (max-width: 1365px) {
  #landing .content-video {
    margin-top: 0;
  }
}
#landing .copyright {
  position: static;
  margin-bottom: 12px;
  font-size: 14px;
  text-align: center;
  color: #b3b3b3;
}
@media (max-width: 1365px) {
  #landing .copyright {
    margin-bottom: 12px;
    font-size: 12px;
  }
}
#landing .copyright > a {
  color: inherit;
}
#landing .copyright > span:nth-child(1) {
  color: inherit;
}
@media (max-width: 767px) {
  #landing .copyright > span:nth-child(1) {
    display: block;
  }
}